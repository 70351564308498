import { Form, Input, InputNumber, message, Modal, Space, Spin } from "antd";
import { MotionConfig } from "framer-motion";
import React from "react";
import { Api } from "../../../../../api/configApi";
import Boton from "../../../../design/elementos/boton";
import AuthCode from "react-auth-code-input";
import { values } from "mobx";

const rules = [{ required: true, message: "Campo obligatorio" }];

function FormSolicitarDebitoCredito({ widget, setDrawerSolicitud, Get }) {
  const [loading, setLoading] = React.useState(true);
  const [guardando, setGuardando] = React.useState(false);
  const [monto, setMonto] = React.useState(widget.minimo);
  const [montoMaximo, setMontoMaximo] = React.useState(widget.maximo);
  const [montoMinimo, setMontoMinimo] = React.useState(widget.minimo);
  const [atributos, setAtributos] = React.useState({});
  const [losValues, setLosvalues] = React.useState({});
  const [modalCodigo, setModalCodigo] = React.useState(false);
  const [codeSecur, setCodesecur] = React.useState(0);
  const [cargandoModal, setCargandoModal] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    Api(
      "afiliados",
      "GetAtributos",
      { atributos: [widget.minimoAtributo, widget.maximoAtributo] },
      {}
    ).then((res) => {
      setAtributos(res);
      setLoading(false);
      widget.minimoTipo === "porcentaje" &&
        setMontoMinimo((widget.minimo * res[widget.minimoAtributo]) / 100);
      widget.maximoTipo === "porcentaje" &&
        setMontoMaximo((widget.maximo * res[widget.maximoAtributo]) / 100);
    });
  }, []);

  function verificar(values) {
    if (values.monto < montoMinimo) {
      message.error("Monto minimo no alcanzado");
      return;
    }
    if (values.monto > montoMaximo && montoMaximo !== 0) {
      message.error("Monto máximo superado");
      return;
    }
    if (widget.confirmacionEmail) {
      setLosvalues(values);
      setCargandoModal(true);
      setModalCodigo(true);
      Api("temporales", "setCodeEmail").then((res) => {
        setCargandoModal(false);
      });
    } else {
      solicitar(values);
    }
  }

  function solicitar(values) {
    setCargandoModal(true);
    if (
      values.monto < montoMinimo ||
      (values.monto > montoMaximo && montoMaximo !== 0)
    ) {
      message.error("Monto minimo o máximo alcanzado");
      return;
    }
    setGuardando(true);
    Api(
      "multiData",
      "InsertDebitoCredito",
      { widget: widget._id, codeSecur },
      values
    ).then((res) => {
      setGuardando(false);
      setCargandoModal(false);
      if (res.success) {
        Get(widget);
        message.success("Solicitud enviada");
        setDrawerSolicitud(false);
      } else {
        setGuardando(false);
        message.error(res.message || "Algo no salio bien");
      }
    });
  }
  return !loading ? (
    <>
      <Modal
        destroyOnClose
        visible={modalCodigo}
        okText="Confirmar"
        cancelText="Cancelar"
        onCancel={() => setModalCodigo(false)}
        onOk={() => {
          if (codeSecur < 1000) {
            message.error("Código incorrecto");
            return;
          }
          var nes = { ...losValues };
          // mayusculas
          nes.codigoSeguridad = codeSecur.toUpperCase();
          solicitar(nes);
        }}
        confirmLoading={cargandoModal}
      >
        <div>
          <h1>Pin de seguridad</h1>
          <p>Ingresa el pin de seguridad</p>
          <AuthCode
            inputClassName="inputNumeroVerificacion"
            onChange={(code) => setCodesecur(code)}
            length={5}
          />
          <div style={{ marginTop: 5 }}>
            <span>Enviamos a tu email un PIN de seguridad</span>
          </div>
        </div>
      </Modal>
      <Form onFinish={verificar} layout="vertical">
        <Form.Item
          initialValue={widget.minimo}
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="monto"
          label="Monto a retirar"
        >
          <InputNumber
            style={{ width: "100%" }}
            onChange={(e) => setMonto(e)}
          />
        </Form.Item>
        {widget.tipoDebito === "automaticoCoinpayments" && (
          <>
            <Form.Item
              rules={[{ required: true, message: "Campo obligatorio" }]}
              name="direccionWallet"
              label="Dirección Wallet"
            >
              <Input />
            </Form.Item>
            <div
              style={{
                marginBottom: 10,
                padding: 10,
                borderRadius: 5,
                background: "#ffeeee",
              }}
            >
              <span>
                <strong>ATENCIÓN:</strong> Verifica que la dirección sea
                correcta y que corresponda a{" "}
                <strong>{widget.nombreWallet}</strong>, la empresa no se hace
                responsable por estos errores.
              </span>
            </div>
          </>
        )}
        <Space direction="vertical">
          <Space>
            <span>
              Fee:{" "}
              <strong>
                {(monto * widget.feePorcentaje) / 100 + widget.feeUnidad}
              </strong>
            </span>
            <span>
              Recibirás{" "}
              <strong>
                {monto -
                  ((monto * widget.feePorcentaje) / 100 + widget.feeUnidad)}
              </strong>
            </span>
          </Space>
          <Boton
            loading={guardando}
            texto={widget.textoBoton || "Enviar"}
            htmlType="submit"
          />
        </Space>
      </Form>
      <div style={{ marginTop: 20, fontSize: 13, fontWeight: 300 }}>
        <p>Minimo: {montoMinimo}</p>
        <p>Máximo: {montoMaximo}</p>
        <p>{widget.indicaciones || ""}</p>
      </div>
    </>
  ) : (
    <Spin />
  );
}

export default FormSolicitarDebitoCredito;
